import { ReactElement } from 'react';
import globalUiStore from '../../../infrastructure/UI/State/GlobalUiStore/instance';

export interface IDrawerOptionConfig {
  translationLink: ReactElement;
  locale: 'en' | 'de';
}

interface IDrawerMenuEntry {
  type: 'link' | 'divider' | 'expansionPanel' | 'bareJsx' | 'function';
  titleId?: string;
  href?: string;
  disableTranslation?: boolean;
  options?: any[];
  component?: ReactElement;
  onClick?: () => void;
}

export const generateDrawerOptions = (config: IDrawerOptionConfig): IDrawerMenuEntry[] =>
{
    return [
        {
            type: 'link',
            titleId: 'Home',
            href: config.locale === 'de' ? '/' : '/en/',
            disableTranslation: true,
        },
        {
            type: 'link',
            titleId: 'about_us',
            href: config.locale === 'de' ? '/ueber-uns/' : '/en/ueber-uns/',
            disableTranslation: false,
        },
        {
            type: 'function',
            titleId: 'subscribe_to_newsletter',
            disableTranslation: false,
            onClick: () =>
            {
                globalUiStore.setNewsletterOpenState(true);
                globalUiStore.setDrawerOpenState(false);
            },
        },
        {
            type: 'divider',
        },
        {
            type: 'expansionPanel',
            titleId: 'publications',
            // href: config.locale === 'de' ? '/publikationen/' : '/en/publikationen/',
            options: [
                {
                    href: config.locale === 'de' ? '/publikationen/' : '/en/publikationen/',
                    titleId: 'all',
                },
                {
                    href: config.locale === 'de' ? '/publikationen/?tag=wahrheit_epistemologie' : '/en/publikationen/?tag=wahrheit_epistemologie',
                    titleId: 'truth_epistemology',
                },
                {
                    href: config.locale === 'de' ? '/publikationen/?tag=existenzgottes' : '/en/publikationen/?tag=existenzgottes',
                    titleId: 'existence_of_god',
                },
                {
                    href: config.locale === 'de' ? '/publikationen/?tag=jesus' : '/en/publikationen/?tag=jesus',
                    titleId: 'jesus_topic_card',
                },
                {
                    href: config.locale === 'de' ? '/publikationen/?tag=diebibel' : '/en/publikationen/?tag=diebibel',
                    titleId: 'the_bible',
                },
                {
                    href: config.locale === 'de' ? '/publikationen/?tag=moral_boesartigkeit' : '/en/publikationen/?tag=moral_boesartigkeit',
                    titleId: 'morality_evil',
                },
                {
                    href: config.locale === 'de' ? '/publikationen/?tag=atheismus' : '/en/publikationen/?tag=atheismus',
                    titleId: 'atheism',
                },
            ],
            disableTranslation: false,
        },
        {
            type: 'divider',
        },
        {
            type: 'link',
            titleId: 'participate',
            href: config.locale === 'de' ? '/teilnehmen/' : '/en/teilnehmen/',
            disableTranslation: false,
        },
        {
            type: 'link',
            titleId: 'what_we_believe',
            href: config.locale === 'de' ? '/was-wir-glauben/' : '/en/was-wir-glauben/',
            disableTranslation: false,
        },
        // {
        //   type: 'link',
        //   titleId: 'recommended_resources',
        //   href: config.locale === 'de' ? '/pages/empfohlene-ressourcen/' : '/en/pages/empfohlene-ressourcen/',
        //   disableTranslation: false,
        // },
        {
            type: 'divider',
        },
        {
            type: 'link',
            titleId: 'privacy_policy',
            href: config.locale === 'de' ? '/datenschutz/' : '/en/datenschutz/',
            disableTranslation: false,
        },
        {
            type: 'link',
            titleId: 'content_disclaimer',
            href: config.locale === 'de' ? '/haftungsausschluss/' : '/en/haftungsausschluss/',
            disableTranslation: false,
        },
        {
            type: 'divider',
        },
        {
            type: 'bareJsx',
            component: config.translationLink,
        },
    ];
};
