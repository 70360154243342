import React, { ReactElement } from 'react';
import NewsletterNotification from './NewsletterNotification/NewsletterNotification';
import SubscriptionResult from './SubscriptionResult';
import Module from '../../../application/Service/Module';

export default function Alerts(): ReactElement[]
{
    const returnElements = [];

    returnElements.push(<NewsletterNotification/>);

    if (Module.get().location.href?.includes('source=newsletter_subscribe_success'))
    {
        returnElements.push(<SubscriptionResult/>);
    }

    return returnElements;
}
