export default class Module
{
    public static get(): Window
    {
        if (typeof window !== 'undefined')
        {
            return window;
        }

        // @ts-ignore
        return {
        // @ts-ignore
            location: {},
        };
    }
}

