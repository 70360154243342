import React from 'react';
import GlobalAppLayoutContainer from '../../redux/GlobalAppLayoutContainer';
import { ThemeProvider } from '@mui/material/styles';
import { muiTheme } from '../../../app_styling/muiTheme';

/**
 * @param {Object} ChildComponent - React Component Child (the element to be wrapped)
 * @param {Object} config - Config object describing how to render Global Layout
 * @param {object} config.props - Props to pass into child component
 */
const withGlobalAppLayout = (Component, props) =>
{
    return (
        <>
            <ThemeProvider theme={muiTheme}>
                <GlobalAppLayoutContainer {...props} />
                <Component {...props} />
            </ThemeProvider>

        </>
    );
};

export default withGlobalAppLayout;
