import { NewsletterResponse } from './NewsletterResponse';

export default class NewsletterResponseClient
{
    private static STORAGE_KEY = 'gf_newsletter_response';

    public hasResponse(): boolean
    {
        return Boolean(localStorage.getItem(NewsletterResponseClient.STORAGE_KEY));
    }

    public hasAccepted(): boolean
    {
        if (!this.hasResponse())
        {
            return false;
        }
        const response = localStorage.getItem(NewsletterResponseClient.STORAGE_KEY);

        return response === NewsletterResponse.ACCEPTED;
    }

    public getResponse(): NewsletterResponse | null
    {
        if (!this.hasResponse())
        {
            return null;
        }

        return localStorage.getItem(NewsletterResponseClient.STORAGE_KEY)! as NewsletterResponse;
    }

    public hasDismissed(): boolean
    {
        if (!this.hasResponse())
        {
            return false;
        }
        const response = localStorage.getItem(NewsletterResponseClient.STORAGE_KEY);

        return response === NewsletterResponse.DISMISSED;
    }

    public accept(): void
    {
        localStorage.setItem(NewsletterResponseClient.STORAGE_KEY, NewsletterResponse.ACCEPTED);
    }

    public dismiss(): void
    {
        localStorage.setItem(NewsletterResponseClient.STORAGE_KEY, NewsletterResponse.DISMISSED);
    }

    public localStorageInaccessible(): boolean
    {
        let accessible = true;
        try
        {
            this.getResponse();
        }
        catch
        {
            accessible = false;
        }

        return !accessible;
    }
}
