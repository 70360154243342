import React, { ReactElement, useEffect, useState } from 'react';
import { Alert, Button, SvgIcon } from '@mui/material';
import globalUiStore from '../../../../infrastructure/UI/State/GlobalUiStore/instance';
import { Close } from '@mui/icons-material';
import * as style from './index.module.css';
import NewsletterResponseClient from '../../../../infrastructure/Newsletter/Service/NewsletterResponseClient/NewsletterResponseClient';

const newsletterResponseClient = new NewsletterResponseClient();

const WAIT_TIME_MS = 5000;

export default function NewsletterNotification(): ReactElement | null
{
    const [
        show,
        setShow,
    ] = useState(false);

    useEffect(() =>
    {
        setTimeout(() =>
        {
            if (globalUiStore.isNewsletterModalOpen() || newsletterResponseClient.hasResponse())
            {
                return;
            }
            setShow(true);
        }, WAIT_TIME_MS);
    });

    if (!show)
    {
        return null;
    }

    if (newsletterResponseClient.localStorageInaccessible() || newsletterResponseClient.hasResponse())
    {
        return null;
    }

    return (
        <Alert
            icon={<div style={{ fontSize: '16px' }}>🎉</div>}
            severity="info"
            variant="standard"
            action={
                <>
                    <Button
                        size="small"
                        onClick={
                            () =>
                            {
                                globalUiStore.setNewsletterOpenState(true);
                                setShow(false);
                            }
                        }
                        variant="contained"
                    >
                        Abonnieren
                    </Button>
                    <SvgIcon
                        onClick={
                            () =>
                            {
                                setShow(false);
                                newsletterResponseClient.dismiss();
                            }}
                        style={{
                            margin: '4px',
                            marginLeft: '18px',
                            cursor: 'pointer',
                        }}
                    >
                        <Close/>
                    </SvgIcon>
                </>
            }
            style={{
                boxShadow: '0 6px 12px rgba(128, 128, 128, 0.46)',
            }}
            className={style.notification_modal}
            title="Newsletter"
        >
            Möchtest Du neue Artikeln per E-Mail erhalten?
        </Alert>
    );
}
