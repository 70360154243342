export default class Util
{
    public static getEmailFieldError(email: string, submitAttempted: boolean): string
    {
        if (!submitAttempted)
        {
            return '';
        }

        if (!email)
        {
            return 'Erforderlich';
        }

        if (!Util.isValidEmail(email))
        {
            return 'Ungültige E-mail Adresse';
        }

        return '';
    }

    public static agreeFieldError(agreeToTerms: boolean, submitAttempted: boolean): string
    {
        if (!submitAttempted)
        {
            return '';
        }

        if (!agreeToTerms)
        {
            return 'Erforderlich';
        }

        return '';
    }

    public static isValidEmail(email: string): boolean
    {
        return new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(email);
    }
}
