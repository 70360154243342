export default class SubscriberAlreadyExists extends Error
{
    private constructor(message: string)
    {
        super(message);
    }

    public static create(): SubscriberAlreadyExists
    {
        return new this('Subscriber already exists.');
    }
}
