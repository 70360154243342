import { SubscribeRequestShape } from './SubscribeRequestShape';

class SubscribeRequest
{
    private readonly _emailAddress: string;
    private readonly _agreement: string;

    private constructor(emailAddress: string, agreement: string)
    {
        this._emailAddress = emailAddress;
        this._agreement = agreement;
    }

    public static create(emailAddress: string, agreement: string): SubscribeRequest
    {
        return new this(
            emailAddress,
            agreement,
        );
    }

    public emailAddress(): string
    {
        return this._emailAddress;
    }

    public agreement(): string
    {
        return this._agreement;
    }

    public toObjectLiteral(): SubscribeRequestShape
    {
        return {
            email: this.emailAddress(),
            agreement: this.agreement(),
        };
    }
}

export default SubscribeRequest;
