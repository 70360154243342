import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@mui/styles';
import { Accordion, AccordionSummary, Drawer } from '@mui/material';
import { List } from '@mui/material';
import { Divider } from '@mui/material';
import { ListItem } from '@mui/material';
import { ListItemText } from '@mui/material';

import { FormattedMessage } from 'react-intl';
import { Link } from 'gatsby';
import { ExpandMore } from '@mui/icons-material';

import { generateDrawerOptions } from './drawerOptions';

const styles = {
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
    anchorLink: {
        textDecoration: 'none',
    },
};

class AppDrawer extends React.Component
{
  toggleDrawer = (bool) =>
  {
      this.props.setDrawerStatus(bool);
  };

  render()
  {
      const {
          classes,
          appDrawerIsOpen,
          locale,
          originalPath,
      } = this.props;

      const translationLink = (index) => (
          <Link key={index} to={locale === 'en' ? originalPath : `/en${originalPath}`} style={{ textDecoration: 'none', color: 'black' }}>
              <ListItem key={index} button={true}>
                  <ListItemText primary={<FormattedMessage id="other_language" />} />
              </ListItem>
          </Link>
      );

      const drawerOptions = generateDrawerOptions({
          translationLink,
          locale,
      });

      const sideListContent = (
          <div className={classes.list}>
              <List>
                  {drawerOptions.map((item, index) =>
                  {
                      if (item.type === 'bareJsx')
                      {
                          return item.component(index);
                      }
                      if (item.type === 'divider')
                      {
                          return <Divider style={{ width: '100%' }} key={index} />;
                      }
                      if (item.disableTranslation && item.type === 'link')
                      {
                          return (
                              <Link onClick={(e) => this.toggleDrawer(false, e)} key={index} style={{ textDecoration: 'none' }} to={item.href} >
                                  <ListItem button={true} key={index}>
                                      <ListItemText style={{ color: 'black' }} primary={item.titleId} />
                                  </ListItem>
                              </Link>
                          );
                      }
                      if (!item.disableTranslation && item.type === 'link')
                      {
                          return (
                              <Link onClick={(e) => this.toggleDrawer(false, e)} key={index} style={{ textDecoration: 'none' }} to={item.href} >
                                  <ListItem button={true} key={index}>
                                      <ListItemText style={{ color: 'black' }} primary={<FormattedMessage id={item.titleId} />} />
                                  </ListItem>
                              </Link>
                          );
                      }
                      if (item.type === 'expansionPanel')
                      {
                          if (!item.options && item.options.length)
                          {
                              return null;
                          }
                          const dividerOptions = item.options.map((option, _index) =>
                          {
                              return (
                                  <Link onClick={(e) => this.toggleDrawer(false, e)} key={_index} style={{ textDecoration: 'none' }} to={option.href} >
                                      <ListItem button={true}>
                                          <ListItemText style={{ paddingLeft: '15px', color: 'black' }} primary={<FormattedMessage id={option.titleId} />} />
                                      </ListItem>
                                  </Link>
                              );
                          });

                          return (
                              <Accordion key={index} elevation={0}>
                                  <AccordionSummary className="mui-listoption" expandIcon={<ExpandMore />}>
                                      <FormattedMessage id={item.titleId} />
                                  </AccordionSummary>
                                  {dividerOptions}
                              </Accordion>
                          );
                      }
                      if (item.type === 'function')
                      {
                          return (
                              <ListItem onClick={item.onClick} button={true} key={index}>
                                  <ListItemText style={{ color: 'black' }} primary={<FormattedMessage id={item.titleId} />} />
                              </ListItem>
                          );
                      }

                      return null;
                  })}
              </List>
          </div>
      );

      return (
          <div>
              <Drawer
                  open={appDrawerIsOpen}
                  onClose={() => this.toggleDrawer(false)}
                  transitionDuration={4}
              >
                  <div
                      tabIndex={0}
                      role="button"
                      // onKeyDown={() => this.toggleDrawer(false)}
                  >
                      {sideListContent}
                  </div>
              </Drawer>
          </div>
      );
  }
}

AppDrawer.propTypes = {
    classes: PropTypes.object.isRequired,
    appDrawerIsOpen: PropTypes.bool,
    setDrawerStatus: PropTypes.func,
    pathname: PropTypes.string,
    locale: PropTypes.string.isRequired,
    userAuth: PropTypes.object,
    originalPath: PropTypes.string.isRequired,
};

export default withStyles(styles)(AppDrawer);
