/* eslint-disable max-len */
export class GFBrand
{
    public static BRAND_DESCRIPTION = 'Glaubensfutter ist ein ökumenischer christlicher Dienst mit Sitz in Deutschland, der die Intellektuelle Vorzüge des Christlichen Glaubens vorstellt.';
    public static THEME = {
        palette: {
            primary: {
                main: '#006666',
                light: '#449494',
                dark: '#003c3c',
            },
            secondary: {
                main: '#664d00',
                light: '#977930',
                dark: '#3b2500',
            },
            info: {
                main: '#664d00',
                light: '#977930',
                dark: '#3b2500',
            },
        },
    }
}
