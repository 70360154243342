import { makeObservable, observable } from 'mobx';

export default class GlobalUiStore
{
    @observable private drawerOpen: boolean;
    @observable private newsletterModalOpen: boolean;

    public constructor()
    {
        makeObservable(this);
        this.drawerOpen = false;
        this.newsletterModalOpen = false;
    }

    public getDrawerOpenState = (): boolean =>
    {
        return this.drawerOpen;
    };

    public setDrawerOpenState = (newState: boolean): void =>
    {
        this.drawerOpen = newState;
    };

    public isNewsletterModalOpen = (): boolean =>
    {
        return this.newsletterModalOpen;
    };

    public setNewsletterOpenState = (newState: boolean): void =>
    {
        this.newsletterModalOpen = newState;
    };
}
