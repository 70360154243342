import { Alert } from '@mui/material';
import React, { ReactElement, useState } from 'react';

export default function SubscriptionResult(): ReactElement | null
{
    const [
        show, setShow,
    ] = useState(true);

    if (!show)
    {
        return null;
    }

    return (
        <Alert
            style={{
                position: 'fixed',
                top: '70px',
            }}
            onClose={() => setShow(false)}
            severity="success"
            variant="standard"
            elevation={24}
        >
            Newsletter erfolgreich abonniert!
        </Alert>
    );
}
