import React, { useEffect, useState } from 'react';
import * as style from './index.module.css';
import { TLocale } from '../../../../infrastructure/Type/Locale';
import { Button, Checkbox, FormControlLabel, FormGroup, SvgIcon } from '@mui/material';
import TextField from '@mui/material/TextField';
import { CheckCircle, Close, Lock } from '@mui/icons-material';
import RouteGenerator from '../../../../infrastructure/Navigation/RouteGenerator';
import NewsletterClient from '../../../../infrastructure/Newsletter/Service/NewsletterClient';
import SubscribeRequest from '../../../../domain/Newsletter/Model/SubscribeRequest';
import parameters from '../../../../application/_config/parameters';
import SubscriberAlreadyExists from '../../../../infrastructure/Newsletter/Exception/SubscriberAlreadyExists';
import Util from './index.util';
import NewsletterResponseClient from '../../../../infrastructure/Newsletter/Service/NewsletterResponseClient/NewsletterResponseClient';
import Module from '../../../../application/Service/Module';
import CircularProgress from '@mui/material/CircularProgress';

interface Props
{
    locale: TLocale;
    showModal: boolean;
    setShowModal: (show: boolean) => void;
}

const newsletterClient = new NewsletterClient();
const newsletterResponseClient = new NewsletterResponseClient();

const NewsletterSubscriptionModal: React.FC<Props> = (props) =>
{
    useEffect(() =>
    {
        if (Module.get().location.href?.includes('open_newsletter_subscribe_dialogue=true'))
        {
            props.setShowModal(true);
        }
    }, []);

    const [
        loading,
        setLoading,
    ] = useState(false);

    const [
        email,
        setEmail,
    ] = useState('');

    const [
        agreeToTerms,
        setAgreeToTerms,
    ] = useState(false);
    const [
        successfullyRequested,
        setSuccessfullyRequested,
    ] = useState(false);

    const [
        submitAttempted,
        setSubmitAttempted,
    ] = useState(false);

    const [
        generalError,
        setGeneralError,
    ] = useState('');

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    {
        const newEmail = event.target.value;
        setEmail(newEmail);
    };

    const handleSubmit = async() =>
    {
        setLoading(true);
        setGeneralError('');
        setSubmitAttempted(true);
        newsletterResponseClient.accept();

        if (!agreeToTerms || !email || !Util.isValidEmail(email))
        {
            setLoading(false);

            return;
        }
        try
        {
            const newsletterRequest = SubscribeRequest.create(
                email,
                parameters.newsletter_agreement,
            );
            await newsletterClient.sendSubscriptionRequest(newsletterRequest);
            setSuccessfullyRequested(true);
        }
        catch (error)
        {
            setLoading(false);
            if (error instanceof SubscriberAlreadyExists)
            {
                setSuccessfullyRequested(true);

                return;
            }
            setGeneralError('Server Fehler');
        }

        setLoading(false);
    };

    if (!props.showModal)
    {
        return null;
    }

    const subscribeForm = (
        <div className={style.subscribe_form_container}>
            <div
                style={{
                    display: 'flex',
                }}
            >
                <h3 style={{ lineHeight: '25px' }}>Erhalte regelmäßig christliche Philosophie von Glaubensfutter per E-Mail ✉️</h3>
                <span
                    className={style.close_button}
                    onClick={
                        () =>
                        {
                            props.setShowModal(false);
                            if (successfullyRequested)
                            {
                                newsletterResponseClient.accept();
                            }
                            else
                            {
                                newsletterResponseClient.dismiss();
                            }
                        }
                    }>
                    <SvgIcon>
                        <Close/>
                    </SvgIcon>
                </span>
            </div>
            <br/>
            <br/>
            <FormGroup>
                <TextField
                    value={email}
                    onChange={handleEmailChange}
                    placeholder="E-mail Adresse"
                    error={Boolean(Util.getEmailFieldError(email, submitAttempted))}
                    helperText={Util.getEmailFieldError(email, submitAttempted)}
                />
                <br/>
                <FormControlLabel
                    control={
                        <Checkbox
                            value={agreeToTerms}
                            onChange={(event, checked: boolean) => setAgreeToTerms(checked)}
                        />
                    }
                    labelPlacement="end"
                    label={
                        <div className={style.agreement_element}>
                            Glaubensfutter darf meine E-Mail-Adresse verwenden, um mir neue Publikationen und wichtige Updates zuzusenden. Ich stimme den <a
                                target="_blank"
                                rel="noreferrer"
                                className={style.agreement_link}
                                href={RouteGenerator.forPrivacyPolicyPage(props.locale).getPath()}>Datenschutzhinweisen</a> für den Newsletter zu.
                        </div>
                    }
                />
                <span>
                    {Util.agreeFieldError(agreeToTerms, submitAttempted) ? <span className={style.field_error}>{Util.agreeFieldError(agreeToTerms, submitAttempted)}</span> : null}
                </span>
                <br/>
                <div className={style.ssl_notice}>
                    <SvgIcon className={style.ssl_notice_lock}>
                        <Lock/>
                    </SvgIcon>
                    <span className={style.ssl_notice_text}>SSL verschlüsselte Datenübertragung</span>
                </div>
                <Button color="primary" style={{ marginTop: '2rem' }} variant="contained" onClick={handleSubmit}>Absenden{loading ? (<CircularProgress style={{ marginLeft: '10px', color: 'rgba(255,255,255,0.51)' }} size={20} color="secondary"/>) : null}</Button>
            </FormGroup>
            {generalError ? (<div style={{ textAlign: 'center' }} className={style.field_error}>{generalError}</div>) : null}

        </div>
    );

    const successView = (
        <div className={style.success_view}>
            <span
                className={style.close_button}
                onClick={
                    () =>
                    {
                        props.setShowModal(false);
                    }
                }>
                <SvgIcon>
                    <Close/>
                </SvgIcon>
            </span>
            <div className={style.success_message}>
                <SvgIcon style={{ fontSize: '2.3rem' }}>
                    <CheckCircle style={{ color: '#006666' }}/>
                </SvgIcon>
            </div>
            <div style={{ lineHeight: '2.4rem', fontSize: '1.6rem', margin: '0 auto', fontWeight: 'bold' }}>
                    Erfolgreich!
            </div>

            <div style={{ margin: '0 auto', marginTop: '2rem', width: '90%' }}>
                Du wirst in Kürze eine Bestätigungs-E-Mail erhalten.
            </div>

            <br/>
            <br/>

        </div>
    );

    return (
        <>
            <div className={style.opaque_background}>

            </div>
            <div className={style.modal_container}>
                {successfullyRequested ? successView : subscribeForm}
            </div>
            ;
        </>
    );
};

export default NewsletterSubscriptionModal;
