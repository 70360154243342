import parameters from '../../application/_config/parameters';
import { TLocale } from '../Type/Locale';

class RouteGenerator
{
    public constructor(
        private route: string = '',
        private publicUrl: string = parameters.publicUrl!,
    ) {}

    public static forSinglePublicationPage(slug: string, id: string, locale: TLocale): RouteGenerator
    {
        const self = new this();
        if (locale === 'en')
        {
            self.route += '/en';
        }
        self.route += `/publikationen/view/${slug}/${id}/`;

        return self;
    }

    public static forPublicationsListing(locale: TLocale): RouteGenerator
    {
        const self = new this();
        if (locale === 'en')
        {
            self.appendToRoute('/en');
        }

        self.appendToRoute('/publikationen/');

        return self;
    }

    public static forPublicUrl(appendPath: string = ''): string
    {
        const self = new this();
        self.withPublicUrl();
        self.route += appendPath;

        return self.route;
    }

    public static forLandingPage(locale: TLocale): RouteGenerator
    {
        const self = new this();
        self.appendLocaleToRoute(locale);
        self.appendToRoute('/');

        return self;
    }

    public static forPrivacyPolicyPage(locale: TLocale): RouteGenerator
    {
        const self = new this();
        self.appendLocaleToRoute(locale);
        self.appendToRoute('/datenschutz/');

        return self;
    }

    public static forAboutUsPage(locale: TLocale): RouteGenerator
    {
        const self = new this();
        self.appendLocaleToRoute(locale);
        self.appendToRoute('/ueber-uns/');

        return self;
    }

    public static forWhatWeBelievePage(locale: TLocale): RouteGenerator
    {
        const self = new this();
        self.appendLocaleToRoute(locale);
        self.appendToRoute('/was-wir-glauben/');

        return self;
    }

    public static forParticipatePage(locale: TLocale): RouteGenerator
    {
        const self = new this();
        self.appendLocaleToRoute(locale);
        self.appendToRoute('/teilnehmen/');

        return self;
    }

    public getFullUrl(): string
    {
        return this.withPublicUrl().route;
    }

    public getPath(): string
    {
        return this.route;
    }

    public withPublicUrl(): RouteGenerator
    {
        this.route = this.publicUrl + this.route;

        return this;
    }

    public appendLocaleToRoute(locale: TLocale): RouteGenerator
    {
        if (locale === 'en')
        {
            this.route += '/en';
        }

        return this;
    }

    private appendToRoute(stringToAppend: string): RouteGenerator
    {
        this.route += stringToAppend;

        return this;
    }
}

export default RouteGenerator;
