const parameters = {
    environment: process.env.GATSBY_ACTIVE_ENV!,
    analyticsId: process.env.ANALYTICS_ID!,
    gfApiUrl: process.env.API_URL!,
    publicUrl: process.env.PUBLIC_URL!,
    newsletter_agreement: 'Mit der Zustimmung zum Erhalt unseres Newsletters erklärt sich der Nutzer mit der Speicherung seiner E-Mail-Adresse einverstanden, um neue Publikationen von Glaubensfutter per E-Mail zu erhalten, sowie andere unregelmäßige, aber wichtige Informationen über die Aktivitäten und Möglichkeiten von Glaubensfutter, mehr über die christliche Philosophie zu erfahren.',
};

export default parameters;

