import { NewsletterClientInterface } from '../../../domain/Newsletter/Service/NewsletterClientInterface';
import SubscribeRequest from '../../../domain/Newsletter/Model/SubscribeRequest';
import axios from 'axios';
import parameters from '../../../application/_config/parameters';
import SubscriberAlreadyExists from '../Exception/SubscriberAlreadyExists';

export default class NewsletterClient implements NewsletterClientInterface
{
    private static API_URL = parameters.gfApiUrl + '/v1/newsletter/prospect';

    public async sendSubscriptionRequest(request: SubscribeRequest): Promise<void>
    {
        try
        {
            await axios.request({
                url: NewsletterClient.API_URL,
                method: 'POST',
                data: request.toObjectLiteral(),
            });
        }
        catch (error)
        {
            // @ts-ignore
            if (error.status && error.status === 409)
            {
                throw SubscriberAlreadyExists.create();
            }
        }
    }
}
