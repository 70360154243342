import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@mui/styles';
import SvgIcon from '@mui/material/SvgIcon';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';

import { Link } from 'gatsby';

const styles = {
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginLeft: '-12px !important',
        marginRight: '20px !important',
    },
};

class AppMenuBar extends Component
{
    toggleDrawer = () =>
    {
        this.props.setDrawerStatus(true);
    };

    renderGfLogo()
    {
        if (this.props.hideGfLogo)
        {
            return null;
        }

        return (
            <Link
                to="/"
                className="gf-appbar-logo"
                style={{
                    textDecoration: 'none',
                    color: 'white',
                    fontFamily: 'Mayflower Antique',
                    textTransform: 'uppercase',
                }}
            >Glaubensfutter</Link>
        );
    }

    render()
    {
        const {
            classes,
            originalPath,
        } = this.props;

        return (
            <div className={classes.root}>
                <AppBar
                    color="primary"
                    position="fixed"
                >
                    <Toolbar>
                        <IconButton
                            onClick={this.toggleDrawer}
                            className={classes.menuButton}
                            color="inherit"
                            aria-label="Menu"
                            style={{ padding: '8px' }}
                            size="large">
                            <SvgIcon style={{ fontSize: '35px' }}>
                                <path d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z"/>
                            </SvgIcon>
                        </IconButton>
                        {this.renderGfLogo()}
                        <Link to={this.props.locale === 'en' ? originalPath : `/en${originalPath}`}
                            style={{
                                cursor: 'pointer',
                                float: 'right',
                                color: 'white',
                                marginLeft: 'auto',
                                marginRight: '.1rem',
                                fontWeight: 500,
                                textDecoration: 'none',
                            }}
                        >
                            {this.props.locale === 'de' ? 'EN' : 'DE'}
                        </Link>
                    </Toolbar>
                </AppBar>
            </div>
        );
    }
}

AppMenuBar.propTypes = {
    originalPath: PropTypes.string,
    classes: PropTypes.object.isRequired,
    setDrawerStatus: PropTypes.func,
    locale: PropTypes.string,
    hideGfLogo: PropTypes.bool,
};

export default withStyles(styles)(AppMenuBar);
