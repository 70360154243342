import React, { Component } from 'react';
import AppDrawer from '../AppDrawer';
import AppMenuBar from '../AppMenuBar';
import PropTypes from 'prop-types';
import globalUiStore from '../../../infrastructure/UI/State/GlobalUiStore/instance';
import { observer } from 'mobx-react';
import NewsletterSubscriptionModal from '../Newsletter/NewsletterSubscriptionModal/NewsletterSubscriptionModal';
import Alerts from '../Alerts/Alerts';

@observer
class GlobalAppLayout extends Component
{
    render()
    {
        const {
            pageContext,
            pathname,
            hideGfLogo,
            userAuth,
        } = this.props;

        return (
            <div>
                <AppDrawer
                    appDrawerIsOpen={globalUiStore.getDrawerOpenState()}
                    setDrawerStatus={globalUiStore.setDrawerOpenState}
                    locale={pageContext.locale}
                    pathname={pathname}
                    userAuth={userAuth}
                    originalPath={this.props.pageContext.originalPath}
                />
                <AppMenuBar
                    setDrawerStatus={globalUiStore.setDrawerOpenState}
                    locale={pageContext.locale}
                    hideGfLogo={hideGfLogo}
                    originalPath={this.props.pageContext.originalPath}
                />
                <div className="header-spacer"/>
                <NewsletterSubscriptionModal
                    showModal={globalUiStore.isNewsletterModalOpen()}
                    setShowModal={globalUiStore.setNewsletterOpenState}
                    locale={pageContext.locale}
                />
                <Alerts/>
            </div>
        );
    }
}

GlobalAppLayout.propTypes = {
    appDrawerIsOpen: PropTypes.bool,
    setDrawerStatus: PropTypes.func,
    pageContext: PropTypes.object,
    pathname: PropTypes.string,
    hideGfLogo: PropTypes.bool,
    setUser: PropTypes.func,
    userAuth: PropTypes.object,
};

export default GlobalAppLayout;
